.language-button-group {
    margin: 20px 0;
  }
  
  .language-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    margin: 0 10px;
    font-size: 16px;
  }
  
  .language-button-group .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .language-button-group .btn-outline-primary {
    background-color: white;
    border-color: #007bff;
    color: #007bff;
  }
  
  .language-button-group .btn-outline-primary:hover {
    background-color: #e7f1ff;
    border-color: #007bff;
    color: #007bff;
  }
  